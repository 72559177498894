
.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: black;
}

a{
  text-decoration: none;
  color: inherit;
}
a:hover{
  text-decoration: underline;
}

#cardContainer {
  aspect-ratio: 4725 / 2658;
  width: 1400px;
  margin: auto;
  background-color: #fef6f3;
  background-image: url(../public/img/bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transform-origin: 0 0;
}

.portrait {
  position: relative;
  width: 25%;
  aspect-ratio: 1 / 1;
  background-size: cover;
  background-image: url(../public/img/portrait.jpg);
  border-radius: 50%;
  flex-shrink: 0;
  margin-left: 15%;
}

.langText{
  font-size: 20px;
}
.langTextZh{
  font-size: 17px;
}
.langText>a{
  padding: 8px;
  display: inline-block;
}

.inviteText {
  font-size: 24px;
  color: #e35961;
  margin-right: 15%;
  margin-left: 5%;
  font-family: 'Frank Ruhl Libre', 'Noto Sans SC', serif;
  font-weight: 300;
}


.t1 {
  font-family: 'Frank Ruhl Libre', 'Ma Shan Zheng', serif;
  font-weight: 900;
  font-size: 48px;
}

.t2 {
  font-family: 'Oooh Baby', 'Ma Shan Zheng', cursive;
  font-size: 48px;
  margin: 12px 0;
}

.t3 {
  text-transform: none;
  line-height: 26px;
}

.t3sub {
  font-size: 22px;
}

.t4 {
  text-transform: uppercase;
  font-size: 18px;
}

.t5 {
  font-family: 'Oooh Baby', 'Ma Shan Zheng', cursive;
  font-weight: 400;
  font-size: 26px;
  margin: 24px 0;
  letter-spacing: 1px;
  line-height: 28px;
}

.no-desktop {
  display: none;
}
@media only screen and (max-width: 900px) {
  #cardContainer {
    background-image: url(../public/img/bgv.jpg);
    aspect-ratio: 2658 / 4725;
    width: 900px;
    flex-direction: column;
    justify-content: flex-start;
  }

  .portrait {
    width: 30%;
    margin-left: 0;
    margin-top: 16%;
    position: relative;
  }

  .inviteText {
    font-size: 46px;
    margin: 5% 13%;
  }

    .langText {
      font-size: 36px;
    }
  
    .langTextZh {
      font-size: 33px;
    }
  
    .langText>a {
      padding: 0 16px 16px 16px;
    }

  .t1 {
    font-size: 72px;
    line-height: 72px;
  }

  .t2 {
    font-size: 62px;
  }

  .t3 {
    font-size: 44px;
  line-height: 50px;
  }

  .t3sub {
    font-size: 34px;
  }

  .t4 {
    font-size: 30px;
  }

  .t5 {
    font-size: 38px;
  line-height: 44px;
  }
  
  .no-mobile{
    display: none;
  }
  .no-desktop{
    display: block;
  }
}

