@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300;900&family=Ma+Shan+Zheng&family=Noto+Sans+SC&family=Oooh+Baby&display=swap');

html {
  background-color: black;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

[hidden] {
  display: none !important;
}
button {
  cursor: pointer;
}
#root {
  font-family: 'Frank Ruhl Libre', 'Noto Sans SC', serif;
}

#error-page {
  color: white;
  background-color: black;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}