.header {
    background-color: rgba(0, 0, 0, 0);
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-size: 20px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7), 1px -1px 3px rgba(0, 0, 0, 0.7), -1px -1px 3px rgba(0, 0, 0, 0.7), -1px 1px 3px rgba(0, 0, 0, 0.7);
    white-space: nowrap;
    z-index: 1;
}

.headerBar {
    display: flex;
    align-items: center;
}

.headerTitle {
    font-family: 'Oooh Baby', 'Ma Shan Zheng', cursive;
    font-size: 40px;
}

.headerTitle>a:hover {
    text-decoration: none;
}

.headerSpacer {
    flex: 1;
}

.headerLink>a {
    padding: 16px;
}

.headerLang>a {
    padding: 16px 8px;
}

.headerLang.zh {
    font-size: 16px;
}

button.headerBtn {
    display: none;
    color: white;
    height: 40px;
}

.menuDiv>.MuiPaper-root {
    background-color: rgba(0, 0, 0, 0.9);
    color: white;
}

.menuDiv .MuiButtonBase-root {
    font-family: 'Frank Ruhl Libre', 'Noto Sans SC', serif;
}

.homePortrait {
    width: 100%;
    max-width: 300px;
    aspect-ratio: 1;
    margin: 120px auto 90px;
    display: block;
    border-radius: 300px;
}

.homeText {
    background-color: rgba(0, 0, 0, 0.4);
    padding: 24px;
    color: white;
    font-size: 18px;
}

.homeTitle {
    font-size: 48px;
    text-align: center;
    font-family: 'Oooh Baby', 'Ma Shan Zheng', cursive;
}

.homeSubtitle {
    font-size: 24px;
    text-align: center;
}

.bg1 {
    background-image: url(../../public/img/bg1.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    aspect-ratio: 4000/5531;
    padding-top: 60px;
}

.bgp {
    background-image: url(../../public/img/bg1.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 30px;
    text-align: center;
}

.bgpTitle {
    font-size: 48px;
    color: white;
    padding: 90px 0;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 1);
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
        flex-wrap: wrap;
}
    
.ImgBackdrop {
    z-index: 1;
}

.fullScreenImg {
    min-width: 100vw;
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    text-align: right;
    padding: 8px;
    z-index: 9;
    display: flex;
    align-items: center;
}

.MuiButtonBase-root.ImgClose {
    position: absolute;
    top: 8px;
    right: 8px;
}

.ImgNav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
}

.imgInfoDiv {
    position: absolute;
    bottom: 0;
    text-align: left;
    width: 40%;
    background-color: rgba(0, 0, 0, 0.5);
    left: 30%;
    padding: 8px 16px;
    border-radius: 3px 3px 0 0;
}

.imgTitle {
    font-weight: bold;
}

.imgDesc {
    font-size: 14px;
    white-space: pre-wrap;
}

.imageActions {
    background-color: #121212;
    border-radius: 0 0 4px 4px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    border-top: 0px;
}
.photosDiv {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    background-image: url(../../public/img/bg2.jpg);
    background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
        box-shadow: inset 0px -50px 50px 0px #000000, inset 0px 50px 50px 0px #000000;
}

.loadingProgress.MuiCircularProgress-root {
    margin: 40px auto;
    display: block;
}

.addBtn {
    display: inline-flex;
    align-items: center;
    margin-left: 16px;
    font-size: 15px;
    border-radius: 100px;
    border: 0;
    background-color: rgba(0, 0, 0, 0.4);
    color: #eee;
    padding: 8px 16px;
}

.addBtnLight {
    border: 1px solid white;
    display: flex;
    margin: 24px auto;
}

#upload {
    padding-bottom: 96px;
    padding-top: 48px;
}

.formDiv {
    background-color: rgba(0, 0, 0, 0.57);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 6px;
    padding: 16px 0 12px 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.imgElement {
    cursor: pointer;
    display: block;
    min-height: 40px;
    width: 100%;
}
.deletePhotoPreview {
    max-width: 260px;
    max-height: 260px;
}
.form50 {
    flex: 1;
    max-width: 50%;
}

.formDiv>div {
    margin-right: 16px;
}

.memoryGrid {
    display: flex;
    gap: 16px;
}

.memoryCard .MuiCardContent-root {
    padding-top: 0;
    white-space: pre-wrap;
}

@media only screen and (max-width: 800px) {
    .headerLink {
        display: none;
    }

    button.headerBtn {
        display: block;
    }
}

@media only screen and (max-width: 380px) {
    .headerTitle {
        font-size: 26px;
        line-height: 60px;
    }
}

@media only screen and (max-width: 560px) {
    .formDiv {
        flex-direction: column;
        align-items: stretch;
        align-content: center;
    }

    .form50 {
        max-width: 100%;
    }
}